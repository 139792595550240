/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


.result-title
{
 color: #4e73df !important;
 font-weight: bold;
}

.clearButton
{
    margin-left: -40px; 
    z-index: 100;
}

.page-link
{
    color: #858796;
}

.result-controls
{
    padding: 1px 1px 5px 1px;
}

.topbar
{
    height: auto;
}

.topbar .navbar-search {
    width: 100%;
}

#rfp_breadcrumb_ol{
  background-color: transparent;
}

.row-deleted .dsg-cell {
  /* Red */
  background: #fff1f0;
}

.row-created .dsg-cell {
  /* Green */
  background: #f6ffed;
}

.row-updated .dsg-cell {
  /* Orange */
  background: #fff7e6;
}

.iconic_icon {
  width: 20px;
  height: 20px;
}

.link_normal_color{
  color: inherit
}

.widget-26 {
  color: #3c4142;
  font-weight: 400;
}

.widget-26 .widget-26-job-category {
    padding: 0.5rem;
    display: inline-flex;
    white-space: nowrap;
    border-radius: 15px;
    background-color: #d1f6f2;
}

#rfp_nav{
  background-color: #093e73;
}

  .rfp-app {
      display: flex;
      flex-direction: row;
      background: #fff;
  }
  
  .rfp-app nav {
      flex: 0 0 200px;
      padding: 1rem;
      border-right: 1px solid #e1e6ef;
  }
  
  .rfp-app nav .btn-block {
      margin-bottom: 15px;
  }
  
  .rfp-app nav .nav {
      flex-direction: column;
  }
  
  .rfp-app nav .nav .nav-item {
      position: relative;
  }
  
  .rfp-app nav .nav .nav-item .nav-link,
  .rfp-app nav .nav .nav-item .navbar .dropdown-toggle,
  .navbar .rfp-app nav .nav .nav-item .dropdown-toggle {
      color: #151b1e;
      border-bottom: 1px solid #e1e6ef;
  }
  
  .rfp-app nav .nav .nav-item .nav-link i,
  .rfp-app nav .nav .nav-item .navbar .dropdown-toggle i,
  .navbar .rfp-app nav .nav .nav-item .dropdown-toggle i {
      width: 20px;
      margin: 0 10px 0 0;
      font-size: 14px;
      text-align: center;
  }
  
  .rfp-app nav .nav .nav-item .nav-link .badge,
  .rfp-app nav .nav .nav-item .navbar .dropdown-toggle .badge,
  .navbar .rfp-app nav .nav .nav-item .dropdown-toggle .badge {
      float: right;
      margin-top: 4px;
      margin-left: 10px;
  }
  
  .rfp-app main {
      min-width: 0;
      flex: 1 1;
      /* padding: 1rem; */
  }
  
  .rfp-app .inbox .toolbar {
      padding: 1rem 1rem 1.5rem 1rem;
      border-bottom: 1px solid #e1e6ef;
      background-color: #0056b3;
  }
  
  .rfp-app .inbox .results {
      padding: 0;
      list-style: none;
  }
  
  .rfp-app .inbox .result {
      position: relative;
      padding: 1rem 1rem;
      cursor: pointer;
      border-bottom: 1px solid #e1e6ef;
  }
  
  .rfp-app .inbox .result:hover {
      background: #f9f9fa;
  }
  
  .rfp-app .inbox .result .actions {
      position: absolute;
      left: 0;
      display: flex;
      flex-direction: column;
  }
  
  .rfp-app .inbox .result .actions .action {
      width: 2rem;
      margin-bottom: 0.5rem;
      color: #c0cadd;
      text-align: center;
  }
  
  .rfp-app .inbox .result a {
      color: #000;
  }
  
  .rfp-app .inbox .result a:hover {
      text-decoration: none;
  }
  
  .rfp-app .inbox .result.unread .header,
  .rfp-app .inbox .result.unread .title {
      font-weight: bold;
  }
  
  .rfp-app .inbox .result .header {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;
  }
  
  .rfp-app .inbox .result .header .date {
      margin-left: auto;
  }
  
  .rfp-app .inbox .result .title {
      margin-bottom: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  
  .rfp-app .inbox .result .description {
      font-size: 12px;
  }
  
  .rfp-app .result .toolbar {
      padding-bottom: 1rem;
      border-bottom: 1px solid #e1e6ef;
  }
  
  .rfp-app .result .details .title {
      padding: 1rem 0;
      font-weight: bold;
  }
  
  .rfp-app .result .details .header {
      display: flex;
      padding: 1rem 0;
      margin: 1rem 0;
      border-top: 1px solid #e1e6ef;
      border-bottom: 1px solid #e1e6ef;
  }
  
  .rfp-app .result .details .header .avatar {
      width: 40px;
      height: 40px;
      margin-right: 1rem;
  }
  
  .rfp-app .result .details .header .from {
      font-size: 12px;
      color: #9faecb;
      align-self: center;
  }
  
  .rfp-app .result .details .header .from span {
      display: block;
      font-weight: bold;
  }
  
  .rfp-app .result .details .header .date {
      margin-left: auto;
  }
  
  .rfp-app .result .details .attachments {
      padding: 1rem 0;
      margin-bottom: 1rem;
      border-top: 3px solid #f9f9fa;
      border-bottom: 3px solid #f9f9fa;
  }
  
  .rfp-app .result .details .attachments .attachment {
      display: flex;
      margin: 0.5rem 0;
      font-size: 12px;
      align-self: center;
  }
  
  .rfp-app .result .details .attachments .attachment .badge {
      margin: 0 0.5rem;
      line-height: inherit;
  }
  
  .rfp-app .result .details .attachments .attachment .menu {
      margin-left: auto;
  }
  
  .rfp-app .result .details .attachments .attachment .menu a {
      padding: 0 0.5rem;
      font-size: 14px;
      color: #e1e6ef;
  }
  
  @media (max-width: 767px) {
      .rfp-app {
          flex-direction: column;
      }
      .rfp-app nav {
          flex: 0 0 100%;
      }
  }
  
  @media (max-width: 575px) {
      .rfp-app .result .header {
          flex-flow: row wrap;
      }
      .rfp-app .result .header .date {
          flex: 0 0 100%;
      }
  }

  .category {
    padding: 0.5rem;
    display: inline-flex;
    white-space: nowrap;
    border-radius: 15px;
    background-color: #e1f5f7;
    text-transform: uppercase;
    font-size: smaller;
  }

  /* .title {
    color: #e1f5f7;
    text-transform: uppercase;
    font-weight: bolder;
    padding:0px;
    margin:0px;
  } */

  .icon-control {
    margin-top: 5px;
    float: right;
    font-size: 80%;
  }
  

  .card-header .title {
    margin-bottom: 0;
    line-height: 1.5;
  }
  
  
  .filter-group {
    border-bottom: 1px solid #e4e4e4;
  }
  .filter-group .card-header {
    border-bottom: 0;
  }
  .filter-group:last-child {
    border-bottom: 0;
  }
  
/* .nav-link
{
  font-weight: bold;
  text-transform: uppercase;
}

/* 
from Eric Fordelon: commenting this out as it is making most of the RFP Page text blue.  Not sure about repercussions.
.active {
    color: #007bff !important;
} */

.search_option{
  color: #007bff;
}


.summary-card {
    /* background-color: #b2c2f1; Dark background */
    color: #4e73df; /* Light text */
  }

  .summary-card .card-header {
    background-color: #b2c2f1; /* Even darker shade for the header */
    color:white;
  }

  .badge-custom {
    margin-right: 5px;
    color: #6c757d;
  }
